<template>
    <!-- footer section start  -->
    <footer class="footer bgn-4 bt">
                  <div class="container">
                      <div class="row justify-content-between">
                          <div class="col-lg-6 col-sm-6 br py-lg-20 pt-sm-15 pt-10 footer-card-area">
                              <div class="py-lg-10">
                                  <div class="footer-logo mb-8">
                                      <router-link to="/" class="d-grid gap-6">
                                          <div class="flogo-1">
                                              <img class="w-100" src="../../assets/image/logo2.png" alt="favicon">
                                          </div>
                                          <div class="flogo-2">
                                              <img class="w-100" src="../../assets/image/logo.png" alt="logo">
                                          </div>
                                      </router-link>
                                  </div>
                              </div>
                          </div>
                          <div class="col-lg-6 col-sm-6 br br-res py-lg-20 pt-sm-15 pt-10 footer-card-area">
                              <div class="py-lg-10">
                                  <h4 class="footer-title mb-8">{{ t.footer.QLinks }}</h4>
                                  <ul class="footer-list d-grid gap-4">
                                      <li>
                                          <router-link to="/puzzles" class="footer-link d-flex align-items-center tcn-6">
                                              <i class="ti ti-chevron-right"></i> {{ t.tournament.puzzle }}
                                          </router-link>
                                      </li>
                                      <li>
                                          <router-link to="/strategy" class="footer-link d-flex align-items-center tcn-6"> 
                                              <i class="ti ti-chevron-right"></i> {{ t.tournament.strategy }} 
                                          </router-link>
                                      </li>
                                      <li>
                                          <router-link to="/sports" class="footer-link d-flex align-items-center tcn-6"> 
                                              <i class="ti ti-chevron-right"></i> {{ t.tournament.sport }}
                                          </router-link>
                                      </li>
                                      <li>
                                          <router-link to="/adventure" class="footer-link d-flex align-items-center tcn-6"> 
                                              <i class="ti ti-chevron-right"></i> {{ t.tournament.adventure }}
                                          </router-link>
                                      </li>
                                      <li>
                                          <router-link to="/arcade" class="footer-link d-flex align-items-center tcn-6"> 
                                              <i class="ti ti-chevron-right"></i> {{ t.tournament.arcade }}
                                          </router-link>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                          <!-- <div class="col-lg-4 col-sm-6 br py-lg-20 pt-sm-15 pt-10 footer-card-area">
                              <div class="py-lg-10">
                                  <h4 class="footer-title mb-8 title-anim">Explore</h4>
                                  <ul class="footer-list d-grid gap-4">
                                      <li><router-link to="/" class="footer-link d-flex align-items-center tcn-6"> <i
                                                  class="ti ti-chevron-right"></i> Top Players</router-link></li>
                                      <li><router-link to="/" class="footer-link d-flex align-items-center tcn-6"> <i
                                                  class="ti ti-chevron-right"></i> messages</router-link></li>
                                      <li><router-link to="/" class="footer-link d-flex align-items-center tcn-6"> <i
                                                  class="ti ti-chevron-right"></i> Profile</router-link></li>
                                  </ul>
                              </div>
                          </div> -->
                          
                      </div>
                      <div class="row pb-4 pt-lg-4 pt-8">
                          <div class="col-xxl-4 offset-xxl-3 col-lg-6 order-last order-lg-first">
                              <span>Copyright © <span class="currentYear"></span> Geng | Designed by <a
                                      href="" class="tcp-1">Byte </a></span>
                          </div>
                          <div class="col-xxl-3 offset-md-1 col-lg-5">
                              <ul class="d-flex align-items-center gap-lg-10 gap-sm-6 gap-4">
                                  <li><a href="">Terms & Conditions</a></li>
                                  <li><a href="">Privacy Policy</a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
                
              </footer>
              <!-- footer section end  -->
  </template>

<script>
import { t, availableLanguages, setLanguage, currentLanguageCode } from '../../locales/language'
export default {
   name: 'AppFooter',
   setup() {
    return {
        t, availableLanguages, setLanguage, currentLanguageCode
    }
   }
}
</script>

<style>


</style>