import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import NotFounds from '../views/Page/NotFounds.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/adventure',
    name: 'adventure',
    component: () => import(/* webpackChunkName: "adventure" */ '../views/Categorys/AdventureView.vue'),
  },
  {
    path: '/arcade',
    name: 'arcade',
    component: () => import(/* webpackChunkName: "arcade" */ '../views/Categorys/ArcadeView.vue'),
  },
  {
    path: '/puzzles',
    name: 'puzzles',
    component: () => import(/* webpackChunkName: "puzzles" */ '../views/Categorys/PuzzlesView.vue'),
  },
  {
    path: '/sports',
    name: 'sports',
    component: () => import(/* webpackChunkName: "sports" */ '../views/Categorys/SportsView.vue'),
  },
  {
    path: '/strategy',
    name: 'strategy',
    component: () => import(/* webpackChunkName: "strategy" */ '../views/Categorys/StrategyView.vue'),
  },

  {
    path: '/details/:gid',
    name: 'details',
    component: () => import(/* webpackChunkName: "details" */ '../views/Page/Details.vue'),
    props: true,
  },

  {
    path: '/games',
    name: 'games',
    component: () => import(/* webpackChunkName: "gamesView" */ '../views/Page/GamesView.vue')
  },

  {
    path: '/playGames/:gid',
    name: 'PlayGames',
    component: () => import(/* webpackChunkName: "playGames" */ '../views/Page/PlayGames.vue'),
    props: true,
  },

  {
    path: '/searchs/:search_id',
    name: 'searchs',
    component: () => import(/* webpackChunkName: "searchs" */ '../views/Page/Searchs.vue'),
    props: true,
  },

  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/User/Login.vue'),
  },
  {
    path: '/unsubscribes',
    name: 'Unsubscribes',
    component: () => import(/* webpackChunkName: "unsubscribes" */ '../views/User/Unsubscribes.vue'),
  },
  { path: '/:pathMatch(.*)*', name: 'NotFounds', component: NotFounds },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
