<template>
    <div class="tournament-wrapper alt">
        <div class="container-fluid">
            <div class="row justify-content-between align-items-end mb-8">
                <div class="col">
                    <h2 class="display-four tcn-1 cursor-scale growUp"> {{ t.tournament.MainSections }}</h2>
                </div>
            </div>
            <div class="singletab tournaments-tab">
                <div class="d-between gap-6 flex-wrap mb-lg-15 mb-sm-10 mb-6">
                    <ul class="tablinks d-flex flex-wrap align-items-center gap-3">
                        <li class="nav-links active">
                            <button class="tablink py-sm-3 py-2 px-sm-8 px-6 rounded-pill tcn-1">{{ t.tournament.puzzle }}</button>
                        </li>
                        <li class="nav-links">
                            <button class="tablink py-sm-3 py-2 px-sm-8 px-6 rounded-pill tcn-1">{{ t.tournament.strategy }}</button>
                        </li>
                        <li class="nav-links">
                            <button class="tablink py-sm-3 py-2 px-sm-8 px-6 rounded-pill tcn-1">{{ t.tournament.sport }}</button>
                        </li>
                        <li class="nav-links">
                            <button class="tablink py-sm-3 py-2 px-sm-8 px-6 rounded-pill tcn-1">{{ t.tournament.adventure }}</button>
                        </li>
                        <li class="nav-links">
                            <button class="tablink py-sm-3 py-2 px-sm-8 px-6 rounded-pill tcn-1">{{ t.tournament.arcade }}</button>
                        </li>
                    </ul>
                    <!-- <div class="px-6">
                        <a href="tournaments.html" class="btn-half-border position-relative d-inline-block py-2 bgp-1 px-6 rounded-pill">View More</a>
                    </div> -->
                </div>
                
                <div class="tabcontents">
                    <div class="tabitem active">
                        <div class="row justify-content-md-start justify-content-center  g-6">
                            <div class="col-xxl-3 col-lg-4 col-md-6 col-sm-8" v-for="game in puzzle" :key="game.id">
                                <div class="tournament-card p-xl-4 p-3 pb-xl-8 bgn-4" >
                                    <div class="tournament-img mb-8 position-relative">
                                        <div class="img-area overflow-hidden">
                                            <img class="w-100 harry-img" v-lazy="game.thumbnailUrl" alt="">
                                        </div>
                                        <span class="card-status position-absolute start-0 py-2 px-6 tcn-1 fs-sm">
                                            <a @click="getFeed(game)" class="pointer">
                                                <span class="dot-icon alt-icon ps-3" style="padding-left: 0rem !important;">{{ t.tournament.playnow }}</span>
                                            </a>
                                            
                                        </span>
                                    </div>
                                    <div class="tournament-content px-xxl-4">
                                        <div class="tournament-info mb-5">
                                            <a @click="getFeed(game)" class="d-block">
                                                <h4 class="tournament-title tcn-1 mb-1 cursor-scale growDown title-anim">
                                                    <template v-if="titalOne">
                                                        <a @click="getFeed(game)">{{ game.title_en }}</a>
                                                    </template>
                                                    <template v-if="titalTow">
                                                        <a @click="getFeed(game)">{{ game.title_ar }}</a>
                                                    </template>
                                                    <template v-if="titalThree">
                                                        <a @click="getFeed(game)">{{ game.title_en }}</a>
                                                    </template>
                                                </h4>
                                            </a>
                                            <span class="tcn-6 fs-sm">{{ t.tournament.puzzle }}</span>
                                        </div>
                                        <div class="hr-line line3"></div>
                                        <div class="card-more-info d-between mt-6">
                                            <div class="teams-info d-between gap-xl-5 gap-3">
                                                <div class="player d-flex align-items-center gap-1">
                                                    <i class="ti ti-user fs-base"></i>
                                                    <span class="tcn-6 fs-sm">{{ game.view }} {{ t.tournament.player }}</span>
                                                </div>
                                            </div>
                                            <a @click="getFeed(game)" class="btn2 pointer">
                                                <i class="ti ti-arrow-right fs-2xl"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tabitem">
                        <div class="row justify-content-md-start justify-content-center  g-6">
                            <div class="col-xxl-3 col-lg-4 col-md-6 col-sm-8" v-for="game in strategy" :key="game.id">
                                <div class="tournament-card p-xl-4 p-3 pb-xl-8 bgn-4">
                                    <div class="tournament-img mb-8 position-relative">
                                        <div class="img-area overflow-hidden">
                                            <img class="w-100 harry-img" v-lazy="game.thumbnailUrl" alt="">
                                        </div>
                                        <span class="card-status position-absolute start-0 py-2 px-6 tcn-1 fs-sm">
                                            <a @click="getFeed(game)" class="pointer">
                                                <span class="dot-icon alt-icon ps-3" style="padding-left: 0rem !important;">{{ t.tournament.playnow }}</span>
                                            </a>
                                        </span>
                                    </div>
                                    <div class="tournament-content px-xxl-4">
                                        <div class="tournament-info mb-5">
                                            <a @click="getFeed(game)" class="d-block">
                                                <h4 class="tournament-title tcn-1 mb-1 cursor-scale growDown title-anim">
                                                    <template v-if="titalOne">
                                                        <a @click="getFeed(game)">{{ game.title_en }}</a>
                                                    </template>
                                                    <template v-if="titalTow">
                                                        <a @click="getFeed(game)">{{ game.title_ar }}</a>
                                                    </template>
                                                    <template v-if="titalThree">
                                                        <a @click="getFeed(game)">{{ game.title_en }}</a>
                                                    </template>
                                                </h4>
                                            </a>
                                            <span class="tcn-6 fs-sm">{{ t.tournament.strategy }}</span>
                                        </div>
                                        <div class="hr-line line3"></div>
                                        <div class="card-more-info d-between mt-6">
                                            <div class="teams-info d-between gap-xl-5 gap-3">
                                                <div class="player d-flex align-items-center gap-1">
                                                    <i class="ti ti-user fs-base"></i>
                                                    <span class="tcn-6 fs-sm">{{ game.view }} {{ t.tournament.player }}</span>
                                                </div>
                                            </div>
                                            <a @click="getFeed(game)" class="btn2 pointer">
                                                <i class="ti ti-arrow-right fs-2xl"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tabitem">
                        <div class="row justify-content-md-start justify-content-center  g-6">
                            <div class="col-xxl-3 col-lg-4 col-md-6 col-sm-8" v-for="game in sport" :key="game.id">
                                <div class="tournament-card p-xl-4 p-3 pb-xl-8 bgn-4">
                                    <div class="tournament-img mb-8 position-relative">
                                        <div class="img-area overflow-hidden">
                                            <img class="w-100 harry-img" v-lazy="game.thumbnailUrl" alt="">
                                        </div>
                                        <span class="card-status position-absolute start-0 py-2 px-6 tcn-1 fs-sm">
                                            <a @click="getFeed(game)" class="pointer">
                                                <span class="dot-icon alt-icon ps-3" style="padding-left: 0rem !important;">{{ t.tournament.playnow }}</span>
                                            </a>
                                        </span>
                                    </div>
                                    <div class="tournament-content px-xxl-4">
                                        <div class="tournament-info mb-5">
                                            <a @click="getFeed(game)" class="d-block">
                                                <h4 class="tournament-title tcn-1 mb-1 cursor-scale growDown title-anim">
                                                    <template v-if="titalOne">
                                                        <a @click="getFeed(game)">{{ game.title_en }}</a>
                                                    </template>
                                                    <template v-if="titalTow">
                                                        <a @click="getFeed(game)">{{ game.title_ar }}</a>
                                                    </template>
                                                    <template v-if="titalThree">
                                                        <a @click="getFeed(game)">{{ game.title_en }}</a>
                                                    </template>
                                                </h4>
                                            </a>
                                            <span class="tcn-6 fs-sm">{{ t.tournament.sport  }}</span>
                                        </div>
                                        <div class="hr-line line3"></div>
                                        <div class="card-more-info d-between mt-6">
                                            <div class="teams-info d-between gap-xl-5 gap-3">
                                                <div class="player d-flex align-items-center gap-1">
                                                    <i class="ti ti-user fs-base"></i>
                                                    <span class="tcn-6 fs-sm">{{ game.view }} {{ t.tournament.player }}</span>
                                                </div>
                                            </div>
                                            <a @click="getFeed(game)" class="btn2 pointer">
                                                <i class="ti ti-arrow-right fs-2xl"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tabitem">
                        <div class="row justify-content-md-start justify-content-center  g-6">
                            <div class="col-xxl-3 col-lg-4 col-md-6 col-sm-8" v-for="game in adventure" :key="game.id">
                                <div class="tournament-card p-xl-4 p-3 pb-xl-8 bgn-4">
                                    <div class="tournament-img mb-8 position-relative">
                                        <div class="img-area overflow-hidden">
                                            <img class="w-100 harry-img" v-lazy="game.thumbnailUrl" alt="">
                                        </div>
                                        <span class="card-status position-absolute start-0 py-2 px-6 tcn-1 fs-sm">
                                            <a @click="getFeed(game)" class="pointer">
                                                <span class="dot-icon alt-icon ps-3" style="padding-left: 0rem !important;">{{ t.tournament.playnow }}</span>
                                            </a>
                                        </span>
                                    </div>
                                    <div class="tournament-content px-xxl-4">
                                        <div class="tournament-info mb-5">
                                            <a @click="getFeed(game)" class="d-block">
                                                <h4 class="tournament-title tcn-1 mb-1 cursor-scale growDown title-anim">
                                                    <template v-if="titalOne">
                                                        <a @click="getFeed(game)">{{ game.title_en }}</a>
                                                    </template>
                                                    <template v-if="titalTow">
                                                        <a @click="getFeed(game)">{{ game.title_ar }}</a>
                                                    </template>
                                                    <template v-if="titalThree">
                                                        <a @click="getFeed(game)">{{ game.title_en }}</a>
                                                    </template>
                                                </h4>
                                            </a>
                                            <span class="tcn-6 fs-sm">{{ t.tournament.adventure }}</span>
                                        </div>
                                        <div class="hr-line line3"></div>
                                        <div class="card-more-info d-between mt-6">
                                            <div class="teams-info d-between gap-xl-5 gap-3">
                                                <div class="player d-flex align-items-center gap-1">
                                                    <i class="ti ti-user fs-base"></i>
                                                    <span class="tcn-6 fs-sm">{{ game.view }} {{ t.tournament.player }}</span>
                                                </div>
                                            </div>
                                            <a @click="getFeed(game)" class="btn2 pointer">
                                                <i class="ti ti-arrow-right fs-2xl"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tabitem">
                        <div class="row justify-content-md-start justify-content-center  g-6">
                            <div class="col-xxl-3 col-lg-4 col-md-6 col-sm-8" v-for="game in arcade" :key="game.id">
                                <div class="tournament-card p-xl-4 p-3 pb-xl-8 bgn-4">
                                    <div class="tournament-img mb-8 position-relative">
                                        <div class="img-area overflow-hidden">
                                            <img class="w-100 harry-img" v-lazy="game.thumbnailUrl" alt="">
                                        </div>
                                        <span class="card-status position-absolute start-0 py-2 px-6 tcn-1 fs-sm">
                                            <a @click="getFeed(game)" class="pointer">
                                                <span class="dot-icon alt-icon ps-3" style="padding-left: 0rem !important;">{{ t.tournament.playnow }}</span>
                                            </a>
                                        </span>
                                    </div>
                                    <div class="tournament-content px-xxl-4">
                                        <div class="tournament-info mb-5">
                                            <a @click="getFeed(game)" class="d-block">
                                                <h4 class="tournament-title tcn-1 mb-1 cursor-scale growDown title-anim">
                                                    <template v-if="titalOne">
                                                        <a @click="getFeed(game)">{{ game.title_en }}</a>
                                                    </template>
                                                    <template v-if="titalTow">
                                                        <a @click="getFeed(game)">{{ game.title_ar }}</a>
                                                    </template>
                                                    <template v-if="titalThree">
                                                        <a @click="getFeed(game)">{{ game.title_en }}</a>
                                                    </template>
                                                </h4>
                                            </a>
                                            <span class="tcn-6 fs-sm">{{ t.tournament.arcade }}</span>
                                        </div>
                                        <div class="hr-line line3"></div>
                                        <div class="card-more-info d-between mt-6">
                                            <div class="teams-info d-between gap-xl-5 gap-3">
                                                <div class="player d-flex align-items-center gap-1">
                                                    <i class="ti ti-user fs-base"></i>
                                                    <span class="tcn-6 fs-sm">{{ game.view }} {{ t.tournament.player }}</span>
                                                </div>
                                            </div>
                                            <a @click="getFeed(game)" class="btn2 pointer">
                                                <i class="ti ti-arrow-right fs-2xl"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next';
import { t, availableLanguages, setLanguage, currentLanguageCode } from '../../../locales/language'
export default {
   name: "AppTournament",
    async setup() {

        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie();
        const puzzle = ref([]);
        const strategy = ref([]);
        const sport = ref([]);
        const adventure = ref([]);
        const arcade = ref([]);

        const titalOne = ref(false)
        const titalTow = ref(false)
        const titalThree = ref(false)

         onMounted(() => {
            if (localStorage.getItem('language') === '') {
                titalOne.value =  true;
            } else if (localStorage.getItem('language') === 'AR') {
                titalTow.value = true;
            } else {
                titalThree.value = true;
            }
        })

        try {
            await HTTP.get(`CatogeryById.php?Cat_id=Arcade&LIMIT=8`).then((res) => {
                arcade.value = res.data.getCatogery; 
            });
        } catch (err) {
            console.log(err);
        }

        try {
            await HTTP.get(`CatogeryById.php?Cat_id=Strategy&LIMIT=8`).then((res) => {
                strategy.value = res.data.getCatogery; 
            });
        } catch (err) {
           console.log(err);
        }

        try {
            await HTTP.get(`CatogeryById.php?Cat_id=Sports&LIMIT=8`).then((res) => {
                sport.value = res.data.getCatogery; 
            });
        } catch (err) {
           console.log(err);
        }

        try {
            await HTTP.get(`CatogeryById.php?Cat_id=Adventure&LIMIT=8`).then((res) => {
                adventure.value = res.data.getCatogery; 
            });
        } catch (err) {
           console.log(err);
        }

        try {
            await HTTP.get(`CatogeryById.php?Cat_id=Puzzles&LIMIT=8`).then((res) => {
                puzzle.value = res.data.getCatogery; 
            });
        } catch (err) {
           console.log(err);
        }

        

        const getFeed = (game) => {
            cookie.setCookie('cat', game.category)
            router.push({ name: "details", params: { gid: game.gid } });
        };

        return {
            puzzle,
            strategy,
            sport,
            adventure,
            arcade,
            getFeed,
            t, availableLanguages, setLanguage, currentLanguageCode, titalOne, titalTow, titalThree
        };
   },
  
}
</script>

<style>

</style>