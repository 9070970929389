<template>
    <aside class="sidebar">
        <div class="sidebar-wrapper d-flex">
            <div class="sidebar-menu py-xxl-15 py-sm-10 py-4 px-xxl-5 px-md-3 px-2">
                <div class="d-grid gap-sm-8 gap-4 sidebar-menu-items">
                    <div class="p-lg-2 p-1">
                        <router-link to="/" class="home-btn box-style active">
                            <i class="ti ti-home fs-2xl"></i>
                        </router-link>
                    </div>
                    <ul class="d-grid gap-sm-6 gap-3 p-lg-2 p-1">
                        <li><router-link to="/puzzles" class="menu-link box-style">
                            <i class="ti ti-trophy fs-2xl"></i></router-link>
                        </li>
                        <li><router-link to="/strategy" class="menu-link box-style">
                            <i class="ti ti-device-gamepad fs-2xl"></i></router-link>
                        </li>
                        <li><router-link to="/sports" class="menu-link box-style">
                            <i class="ti ti-device-gamepad-2 fs-2xl"></i></router-link>
                        </li>
                        <li><router-link to="/adventure" class="menu-link box-style">
                            <i class="ti ti-device-gamepad-3 fs-2xl"></i></router-link>
                        </li>
                        <li><router-link to="/arcade" class="menu-link box-style">
                            <i class="ti ti-brand-google-play fs-2xl"></i></router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </aside>
</template>

<script>
export default {
  name: 'AppSidebar',
}
</script>

<style>

</style>