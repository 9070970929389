<template>
  <app-header/>
  <main class="main-container container-fluid d-flex pt-20 px-0 position-relative">
    <app-sidebar />
    <article class="main-content">
      <Suspense>
          <template #default>
              <app-home/>
          </template>
          <template #fallback>
              <app-loading />
          </template>
      </Suspense>
      <app-footer/>
    </article>
  </main>
</template>

<script>
import AppFooter from '../components/layouts/AppFooter.vue'
import AppHeader from '../components/layouts/AppHeader.vue'
import AppHome from '../components/Pages/AppHome.vue'
import AppLoading from '../components/Spinners/AppLoading.vue';
import { Suspense } from "vue";
import AppSidebar from '../components/layouts/AppSidebar.vue';
export default {
  components: { AppHeader, AppHome, AppFooter, AppLoading, Suspense, AppSidebar },
  name: 'Home',
  
}
</script>
