import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import fastclick from 'fastclick'
import lazyPlugin from 'vue3-lazy'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import { VueCookieNext } from 'vue-cookie-next'

fastclick.attach(document.body)
const loadimage = require('./assets/image/geng.jpeg')
createApp(App)
    .use(store)
    .use(router)
    .use(lazyPlugin, {
       preLoad: 1.3,
       loading: loadimage,
       attempt: 1
    })
    .use(Toast)
    .use(VueCookieNext)
    .mount('#app')
