<template>  
    <!-- banner section start -->
    <section class="banner-section pb-120 pt-lg-20 pt-sm-10">
        <div class="container-fluid">
            <div class="row g-4">
                <app-banner/>
            </div>
        </div>
    </section>
    <!-- banner section end --> 
    <!-- tournament section start -->
    <section class="tournament-section pb-120">
        <app-tournament/>
    </section> 
      <!-- game section start  -->
   <section class="game-section pb-120 pt-120">
    <app-carsule />
   </section>
</template>

<script>
import AppBanner from './HomePage/AppBanner.vue'
import AppCarsule from './HomePage/AppCarsule.vue'
import AppTournament from './HomePage/AppTournament.vue'
export default {
  components: { AppBanner, AppTournament, AppCarsule },
   name: 'AppHome',
   mounted() {
    const s = document.createElement('script');
        s.type = 'text/javascript';
        s. src = '/include/js/main.js';
        document.body.appendChild(s);
   }
}
</script>

<style>

</style>