<template>
                <div class="container-fluid">
                    <div class="row align-items-center justify-content-between mb-15">
                        <div class="col-6">
                            <h2 class="display-four tcn-1 cursor-scale growUp ">{{ t.swiper.topPlay }}</h2>
                        </div>
                        <div class="col-6 text-end" style="text-align: left !important;">
                            <div class="px-6">
                                <router-link to="/games" class="btn-half-border position-relative d-inline-block py-2 bgp-1 px-6 rounded-pill">{{ t.swiper.more }}</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="swiper game-swiper2">
                                <div class="swiper-wrapper mb-lg-15 mb-10">
                                    <div class="swiper-slide" v-for="game in allgames" :key="game.id">
                                        <div class="game-card-wrapper mx-auto">
                                            <div class="game-card mb-5 p-2">
                                                <div class="game-card-border"></div>
                                                <div class="game-card-border-overlay"></div>
                                                <div class="game-img">
                                                    <img class="w-100 h-100" v-lazy="game.thumbnailUrl" alt="game">
                                                </div>
                                                <div class="game-link d-center">
                                                    <a @click="getFeed(game)" class="btn2">
                                                        <i class="ti ti-arrow-right fs-2xl"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <a @click="getFeed(game)">
                                                <template v-if="titalOne">
                                                    <h3 class="game-title mb-0 tcn-1 cursor-scale growDown2">{{ game.title_en }}</h3>
                                                </template>
                                                <template v-if="titalTow">
                                                    <h3 class="game-title mb-0 tcn-1 cursor-scale growDown2">{{ game.title_ar }}</h3>
                                                </template>
                                                <template v-if="titalThree">
                                                    <h3 class="game-title mb-0 tcn-1 cursor-scale growDown2">{{ game.title_en }}</h3>
                                                </template>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                                <div class="text-center d-center">
                                    <div class="game-swiper-pagination">
                                        <a @click="getFeed(game)"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
import { t, availableLanguages, setLanguage, currentLanguageCode } from '../../../locales/language'
export default {
    name: "AppCarsule",
    setup() {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie();
        const allgames = ref({});
        const titalOne = ref(false)
        const titalTow = ref(false)
        const titalThree = ref(false)
        
        onMounted(() => {
                if (localStorage.getItem('language') === '') {
                    titalOne.value =  true;
                } else if (localStorage.getItem('language') === 'AR') {
                    titalTow.value = true;
                } else {
                    titalThree.value = true;
                }
            })

        const getFeed = (game) => {
            cookie.setCookie('cat', game.category)
            
            router.push({ name: "details", params: { gid: game.gid } });
        };

        return {
            allgames,
            getFeed,
            t, availableLanguages, setLanguage, currentLanguageCode, titalOne, titalTow, titalThree
        }
    },
    async created() {
        await HTTP.get(`GetMostViewed.php?LIMIT=8`, {
            method: 'GET' //optional
        }).then(async (response) => {
            const allgames = await response.data.MostViewed
            // console.log("allgames", allgames);
            this.allgames = allgames
        }).catch((error) => {return error})
    },
}
</script>

<style>

</style>