<template>
<div class="col-xxl-9">
    <div class="swiper banner-swiper position-relative">
        <div class="banner-bg-img position-absolute w-100">
            <img class="w-100 h-100 rounded-3" src="include/img/hero-banner-bg.png" alt="banner">
        </div>
        <div class="banner-swiper-pagination"></div>
            <div class="swiper-wrapper">
                <div class="swiper-slide overflow-hidden">
                    <div class="banner-content pt-lg-0 pt-6">
                        <div class="row justify-content-lg-between justify-content-center gy-6 align-items-center">
                            <div class="col-1"></div>
                                <div class="col-lg-6 col-md-8 col-11">
                                    <div class="hero-content">
                                        <ul class="d-flex gap-3 fs-2xl fw-semibold heading-font mb-5 list-icon">
                                            <li>{{ t.banner.play }}</li>
                                            <li>{{ t.banner.earn }}</li>
                                            <li>{{ t.banner.enjoy }}</li>
                                        </ul>
                                        <h2 class="hero-title display-two tcn-1 cursor-scale growUp mb-lg-10 mb-sm-8 mb-6">
                                            {{ t.banner.badlandsOne }} <span class="tcp-1">{{ t.banner.badlandsTow }}</span> {{ t.banner.badlandsThree }}
                                        </h2>
                                        <div class="d-flex align-items-center flex-wrap gap-xl-6 gap-3">
                                            <a @click="getFive()" class="btn-half-border position-relative d-inline-block py-2 px-6 bgp-1 rounded-pill">{{ t.tournament.playnow }}</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-7">
                                    <div class="banner-inner-img">
                                        <img class="w-100 h-100" src="../../../assets/image/banner-inner-img14.png" alt="img">
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide overflow-hidden">
                    <div class="banner-content pt-lg-0 pt-6">
                        <div class="row justify-content-lg-between justify-content-center gy-6 align-items-center">
                            <div class="col-1"></div>
                                <div class="col-lg-6 col-md-8 col-11">
                                    <div class="hero-content">
                                        <ul class="d-flex gap-3 fs-2xl fw-semibold heading-font mb-5 list-icon">
                                            <li>{{ t.banner.play }}</li>
                                            <li>{{ t.banner.earn }}</li>
                                            <li>{{ t.banner.enjoy }}</li>
                                        </ul>
                                        <h2 class="hero-title display-two tcn-1 cursor-scale growUp mb-lg-10 mb-sm-8 mb-6">
                                            {{ t.banner.HWillieOne }} <span class="tcp-1"> {{ t.banner.HWillieTow }} </span> {{ t.banner.HWillieThree }}
                                        </h2>
                                        <div class="d-flex align-items-center flex-wrap gap-xl-6 gap-3">
                                            <a @click="getSecand()" class="btn-half-border position-relative d-inline-block py-2 px-6 bgp-1 rounded-pill">{{ t.tournament.playnow }}</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-md-7">
                                    <div class="banner-inner-img">
                                        <img class="w-100" src="../../../assets/image/banner-inner-img10.png" alt="img">
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide overflow-hidden">
                    <div class="banner-content pt-lg-0 pt-6">
                        <div class="row justify-content-lg-between justify-content-center gy-6 align-items-center">
                            <div class="col-1"></div>
                                    <div class="col-lg-6 col-md-8 col-11">
                                        <div class="hero-content">
                                            <ul class="d-flex gap-3 fs-2xl fw-semibold heading-font mb-5 list-icon">
                                                <li>{{ t.banner.play }}</li>
                                                <li>{{ t.banner.earn }}</li>
                                                <li>{{ t.banner.enjoy }}</li>
                                            </ul>
                                            <h2 class="hero-title display-two tcn-1 cursor-scale growUp mb-lg-10 mb-sm-8 mb-6">
                                                {{ t.banner.BasketballOne }} <span class="tcp-1"> {{ t.banner.BasketballTow }} </span> {{ t.banner.BasketballThree }}
                                            </h2>
                                            <div class="d-flex align-items-center flex-wrap gap-xl-6 gap-3">
                                                <a @click="getTherd()" class="btn-half-border position-relative d-inline-block py-2 px-6 bgp-1 rounded-pill">{{ t.tournament.playnow }}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 col-md-7">
                                        <div class="banner-inner-img">
                                            <img class="w-100" src="../../../assets/image/banner-inner-img13.png" alt="img">
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide overflow-hidden">
                        <div class="banner-content pt-lg-0 pt-6">
                            <div class="row justify-content-lg-between justify-content-center gy-6 align-items-center">
                                <div class="col-1"></div>
                                        <div class="col-lg-6 col-md-8 col-11">
                                            <div class="hero-content">
                                                <ul class="d-flex gap-3 fs-2xl fw-semibold heading-font mb-5 list-icon">
                                                    <li>{{ t.banner.play }}</li>
                                                    <li>{{ t.banner.earn }}</li>
                                                    <li>{{ t.banner.enjoy }}</li>
                                                </ul>
                                                <h2 class="hero-title display-two tcn-1 cursor-scale growUp mb-lg-10 mb-sm-8 mb-6">
                                                    {{ t.banner.CanyonOne }} <span class="tcp-1"> {{ t.banner.CanyonTow }} </span> {{ t.banner.CanyonThree }}
                                                </h2>
                                                <div class="d-flex align-items-center flex-wrap gap-xl-6 gap-3">
                                                    <a @click="getFour()" class="btn-half-border position-relative d-inline-block py-2 px-6 bgp-1 rounded-pill">{{ t.tournament.playnow }}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-md-7">
                                            <div class="banner-inner-img">
                                                <img class="w-100" src="../../../assets/image/banner-inner-img12.png" alt="img">
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-slide overflow-hidden">
                        <div class="banner-content pt-lg-0 pt-6">
                            <div class="row justify-content-lg-between justify-content-center gy-6 align-items-center">
                                <div class="col-1"></div>
                                        <div class="col-lg-6 col-md-8 col-11">
                                            <div class="hero-content">
                                                <ul class="d-flex gap-3 fs-2xl fw-semibold heading-font mb-5 list-icon">
                                                    <li>{{ t.banner.play }}</li>
                                                    <li>{{ t.banner.earn }}</li>
                                                    <li>{{ t.banner.enjoy }}</li>
                                                </ul>
                                                <h2 class="hero-title display-two tcn-1 cursor-scale growUp mb-lg-10 mb-sm-8 mb-6">
                                                    {{ t.banner.BikeManiaOne }} <span class="tcp-1"> {{ t.banner.BikeManiaTow }} </span> {{ t.banner.BikeManiaThree }}
                                                </h2>
                                                <div class="d-flex align-items-center flex-wrap gap-xl-6 gap-3">
                                                    <a @click="getFirst()" class="btn-half-border position-relative d-inline-block py-2 px-6 bgp-1 rounded-pill">{{ t.tournament.playnow }}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-md-7">
                                            <div class="banner-inner-img">
                                                <img class="w-100" src="../../../assets/image/banner-inner-img11.png" alt="img">
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </div>
            </div>
    </div>
</div>

<div class="col-xxl-3">
    <div class="card-area bgn-4 py-lg-8 py-6 px-lg-6 px-3 rounded-5 card-tilt" data-tilt>
        <h3 class="tcn-1 dot-icon cursor-scale growDown mb-sm-6 mb-4 " style="right: 15px;">
            {{ t.banner.Suggested }}
        </h3>
        <div class="hr-line mb-sm-6 mb-4"></div>
        <div class="card-items d-grid gap-sm-5 gap-3" v-for="game in allgames" :key="game.id">
            <div class="card-item d-flex align-items-center gap-4" style="margin-top: 10px;">
                <div class="card-img-area rounded-circle overflow-hidden">
                <a @click="getFeed(game)">
                    <img class="w-100" v-lazy="game.thumbnailUrl" alt="profile">
                </a>
                   
                </div>
                <div class="card-info">
                    <h4 class="card-title fw-semibold tcn-1 mb-1 cursor-scale growDown2">
                        <template v-if="titalOne">
                            <a @click="getFeed(game)">{{ game.title_en }}</a>
                        </template>
                        <template v-if="titalTow">
                            <a @click="getFeed(game)">{{ game.title_ar }}</a>
                        </template>
                        <template v-if="titalThree">
                            <a @click="getFeed(game)">{{ game.title_en }}</a>
                        </template>
                    </h4>
                    <!-- <p class="card-text tcs-1 fw-medium">{{ game.title_en }}</p> -->
                </div>
            </div>
            <div class="hr-line "></div>
        </div>
    </div>
</div>
</template>
<script>
import { onMounted, ref } from 'vue';
import { HTTP } from '@/Api/http-common';
import { useCookie } from 'vue-cookie-next'
import { useRouter, useRoute } from "vue-router";
import { t, availableLanguages, setLanguage, currentLanguageCode } from '../../../locales/language'
export default {
    name: "AppBanner",
    setup() {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie();
        const allgames = ref({})
        const titalOne = ref(false)
        const titalTow = ref(false)
        const titalThree = ref(false)
            

            const getFirst = () => {
                router.push({ name: "details", params: { gid: '89M21' } });
            };
            const getSecand = () => {
                router.push({ name: "details", params: { gid: '40141' } });
            };
            const getTherd = () => {
                router.push({ name: "details", params: { gid: '40083' } });
            };
            const getFour = () => {
                router.push({ name: "details", params: { gid: '40400' } });
            };
            const getFive = () => {
                router.push({ name: "details", params: { gid: 'L37Y6' } });
            };

            const getFeed = (game) => {
                cookie.setCookie('cat', game.category)
                router.push({ name: "details", params: { gid: game.gid } });
            };
            
            onMounted(() => {
                if (localStorage.getItem('language') === '') {
                    titalOne.value =  true;
                } else if (localStorage.getItem('language') === 'AR') {
                    titalTow.value = true;
                } else {
                    titalThree.value = true;
                }
            })


            return {
                allgames,
                getFeed,
                getFirst,
                getSecand,
                getTherd,
                getFour,
                getFive,
                t, availableLanguages, setLanguage, currentLanguageCode, titalOne, titalTow, titalThree
            }
    },
    async created() {
        await HTTP.get(`getGamesAll.php?LIMIT=3`, {
            method: 'GET' //optional
        }).then(async (response) => {
            const allgames = await response.data.getAllGames
            // console.log("allgames", allgames);
            this.allgames = allgames
        }).catch((error) => {return error})
    },

}
</script>

<style>

</style>