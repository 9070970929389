<template>
    <!-- header-section start -->
    <header class="header-section bgn-4 w-100">
         <div class="py-sm-6 py-3 mx-xxl-20 mx-md-15 mx-3">
             <div class="d-between gap-xxl-10 gap-lg-6">
                 <div class="top-bar alt d-flex align-items-center gap-6">
                     <button class="sidebar-toggle-btn" type="button">
                         <span></span>
                         <span></span>
                         <span></span>
                         <span></span>
                     </button>
                     <router-link class="navbar-brand d-flex align-items-center gap-4 w-100" to="/">
                         <img class="w-100 logo1 d-block" src="../../assets/image/logo2.png" alt="favicon">
                         <img class="w-100 logo2 d-sm-block d-none" src="../../assets/image/logo.png" alt="logo">
                     </router-link>
                 </div>
                 <div class="header-btn-area d-between gap-6 w-100 position-relative">
                     <!-- search bar start  -->
                     <div class="search-bar w-100">
                         <form @submit.prevent="SearchData()">
                             <div class="input-area d-flex align-items-center gap-3">
                                 <i class="ti ti-search"></i>
                                 <input type="text" v-model="search" :placeholder="t.header.searchs + '.....'">
                             </div>
                         </form>
                     </div>
                     <!-- search bar end  -->
                     <div class="header-btns d-flex align-items-center justify-content-end gap-lg-6 gap-sm-4 gap-2 w-100">
                         <button class="search-toggle-btn toggle-btn box-style fs-2xl d-block d-lg-none">
                             <i class="ti ti-search"></i>
                         </button>
                         <select @change="setLanguage($event.target.value)" v-model="currentLanguageCode" class="btn-rounded-cus border-0 d-flex align-items-center gap-3 p-lg-2 p-0 pe-xl-6 rounded-5 position-relative notification">
                             <option class="text-nowrap d-xl-block" @click="reloadPage()" v-for="lang in availableLanguages" :key="lang.code" :value="lang.code">
                                
                                <span class="text-nowrap d-none d-xl-block">{{ lang.name }}</span>
                             </option>
                         </select> 
                         <button class="btn-rounded-cus wallet-btn border-0 d-flex align-items-center gap-3 p-lg-2 p-0 pe-xl-6 rounded-5 position-relative">
                             <span class="btn-circle fs-2xl">
                                 <i class="ti ti-wallet"></i>
                             </span>
                             <span class="text-nowrap d-none d-xl-block">{{ t.header.login }}</span>
                         </button>                        
                     </div>
                 </div>
             </div>
         </div>
     </header>
     <!-- header-section end -->
 
 
 
     <!-- connect your Wallet section start -->
     <div class="connect-wallet-section position-fixed top-0 start-0 w-100 vh-100">
         <div class="connect-wallet-overlay position-absolute top-0 start-0 w-100 h-100"></div>
         <div class="vh-100 wallet-wrapper d-center">
             <div class="wallet-area pt-lg-8 pt-sm-6 pt-4 pb-lg-20 pb-sm-10 pb-6 px-lg-15 px-sm-8 px-3 bgn-4 rounded-5 ">
                 <div class="mb-lg-7 mb-sm-5 mb-3 d-flex justify-content-end">
                     <i class="ti ti-circle-x display-four fw-normal pointer wallet-close-btn"></i>
                 </div>
                 <h3 class="tcn-1 cursor-scale growDown title-anim mb-lg-20 mb-sm-10 mb-6">
                    {{ t.header.login }}
                 </h3>
                 <div class="wallet-option pb-20">
                     <ul class="d-grid gap-sm-8 gap-4">
                         <li class="wallet-item p-sm-6 p-2 bgn-3 rounded-4">
                             <router-link to="/" class="d-between">
                                 <span>{{ t.header.phoneNumber }} </span>
                                 <div class="wallet-item-thumb">
                                     <img class="w-100" src="../../assets/image/game-console.png" alt="رقم الهاتف">
                                 </div>
                             </router-link>
                         </li>
                         <li class="wallet-item p-sm-6 p-2 bgn-3 rounded-4">
                             <router-link to="/" class="d-between">
                                 <span> {{ t.header.password }}</span>
                                 <div class="wallet-item-thumb">
                                     <img class="w-100" src="../../assets/image/game-console.png" alt="كلمة المرور">
                                 </div>
                             </router-link>
                         </li>
                     </ul>
                 </div>
             </div>
         </div>
     </div>
     <!-- connect your Wallet section end -->
 </template>
<script>
import { useRouter } from "vue-router";
import { ref } from '@vue/reactivity';
import { useCookie } from 'vue-cookie-next'
import { t, availableLanguages, setLanguage, currentLanguageCode } from '../../locales/language'
export default {
    name: 'AppHeader',
    setup() {
        const router = useRouter();
        const search = ref([]);
        const cookie = useCookie();
        

        const SearchData = () => {
            router.push({ name: "searchs", params: { search_id: search.value } });
        }
        const reloadPage = () => {
            window.location.reload();
        }
        
        return {
            SearchData,
            search,
            t, availableLanguages, setLanguage, currentLanguageCode, reloadPage
        }
    },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.notification {
    position: fixed;
  z-index: 1000;
  background: rgb(var(--n3));
  max-width: 300px;
  /* width: 100%; */
  max-height: 400px;
  height: 100%;
  border: 1px solid rgb(var(--n3));
  border-radius: 12px;
  transition: all 0.5s ease;
  overflow-y: auto;
  padding-right: 1.5rem !important;
  color: rgb(255, 255, 255);
}
</style>